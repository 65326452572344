
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import requests from '../../requests';
import Modal from '../UI/Modal';
import Switch from '@material-ui/core/Switch';

import helper from '../helper';
const { ms_to_day } = helper;

export const ModalPlans = ({oldData = {}, recharge }) => {
    const [plan, setplan] = useState(oldData)
    const [services, setservices] = useState([])

    const handleChange = (e) => {
        let { value, id, type } = e.target;
        let plan_modify = plan;

        if(!plan_modify.services) {
            plan_modify.services = [];
        }

        if(type === 'checkbox') {
            // Entonces estamos trabajando con los servicios
            
            // Buscamos el ID
            const service = plan_modify?.services?.find(e => e.id === id);
            if(service) {
                // Lo eliminamos
                plan_modify.services = plan_modify.services.filter(e => e.id !== id)
            } else {
                
                plan_modify.services.push({ 
                    id,
                    value: 0
                })
            }
        } else {
            // Dividimos el id
            const id_divido = id.split('|');
            if(id_divido[0] === 'service') {
                // Entonces buscamos el servicio especificado
                id = id_divido[1];
                const serviceIndex = plan_modify?.services?.findIndex(e => e.id === id);

                if(serviceIndex > -1) {
                    plan_modify.services[serviceIndex].value = value;
                }
            } else {
                plan_modify[id] = value;
            }

        }
        setplan({...plan_modify});
    }

    const delete_plan = async (e) => {
        e.preventDefault();
        const { id } = plan;
        if(!id) return;
        swal({
            title: "¿Estas seguro?",
            text: "Estas por eliminar un plan",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                let req = new requests({
                    name: "plans",
                    extraUrl: `/${id}`
                })

                try {
                    let q = await req.prepareConfig().delete();
                    if(q.Success) {
                        swal('Excelente', 'Plan eliminado exitosamente', 'success').then(() => {
                            recharge();
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e)
                }
            }
        })
    }

    const get_services = async () => {
        let req = new requests({
            name: 'plans',
            extraUrl: '/services'
        })

        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                setservices([...q.Data]);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const send = async (e) => {
        e.preventDefault();
        const { id, name, price, duration, services, description } = plan;

        if(!name || !duration) return;

        if(services.length === 0) {
            swal('Error', 'Debes agregar algun servicio al plan', 'error');
            return;
        }

        let req = new requests({
            name: 'plans',
            body: { name, price, duration, services, description }
        })
        
        try {
            if(id) {
                // Entonces editamos
                req.extraUrl += `/${id}`;
                const q = await req.prepareConfig().put();
                if(q.Success) {
                    swal('Excelente', "Plan modificado exitosamente", 'success').then(() => {
                        recharge();
                    })
                } else {
                    swal('Error', q.Message, 'error').then(() => {
                        recharge();
                    })
                }
            } else {
                // Entonces creamos uno nuevo
                const q = await req.prepareConfig().post();
                if(q.Success) {
                    swal('Excelente', "Plan agregado exitosamente", 'success').then(() => {
                        recharge();
                    })
                } else {
                    swal('Error', q.Message, 'error').then(() => {
                        recharge();
                    })
                }
            }
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error').then(() => {
                recharge();
            })
        }
    }

    useEffect(get_services, []);
    useEffect(() => {
        // Transformamos el array de servicios
        if(plan.services && plan.services.length > 0) {
            plan.services = plan.services.map(elem => {
                return { id: elem.service_id, value: elem.value }
            })
    
            setplan({...plan})
        }

        return () => {}
        
    }, [])

    const toggleActive = () => {
        const { id } = plan;
        if(!id) return;
        swal({
            title: "¿Estas seguro?",
            text: "Estas por cambiar el estado de un plan",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                let req = new requests({
                    name: "plans",
                    extraUrl: `/${id}`,
                    body: { active: !plan.active }
                })

                try {
                    let q = await req.prepareConfig().put();
                    if(q.Success) {
                        swal('Excelente', 'Plan modificado exitosamente', 'success').then(() => {
                            recharge();
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e)
                }
            }
        })
    }

    return (
        <Modal title={oldData.id ? 'Modificar plan' : 'Nuevo plan'} open={true} className="modal_plans">
            <form onSubmit={send}>
                <div className="form-body">

                    <div className="row p-t-20">
                        <div className="offset-md-11 col-12 col-md-1">
                            <h6>Activo</h6>
                            <Switch
                                checked={!!plan.active}
                                onChange={toggleActive}
                                color={!!plan.active ? 'primary' : 'secondary'}
                                name="activePlan"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </div>


                    <div className="row p-t-20">

                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="id">ID: </label>
                                <input required type="text" id="" className="form-control" value={plan.id} disabled/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">Nombre: </label>
                                <input required type="text" id="name" className="form-control" value={plan.name} onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="price">Precio: </label>
                                <input required type="text" id="price" className="form-control" value={plan.price} onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="duration">Duración (ms): </label>
                                <input required type="text" id="duration" className="form-control" value={plan.duration} onChange={handleChange}/>
                                <small>Duración en días: {ms_to_day(plan.duration)}</small>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="description">Descripción: </label>
                                <input required type="text" id="description" className="form-control" value={plan.description} onChange={handleChange}/>
                            </div>
                        </div>
                    </div>


                    {services && 
                        <>
                            <h5>Servicios</h5>
                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <td>Activo</td>
                                        <td>Nombre</td>
                                        <td>Descripción</td>
                                        <td>Valor</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {services.map(v => {
                                        // Buscamos el servicio
                                        let exist = plan?.services?.find(e => e.id === v.id);

                                        return (
                                            <tr key={v._id}>
                                                <td>
                                                    <input type="checkbox" id={v.id} onChange={handleChange} checked={exist}/>
                                                </td>
                                                <td>{v.name}</td>
                                                <td>{v.description}</td>
                                                <td className="input_table">
                                                    <input disabled={!exist} placeholder="value" id={`service|${v.id}`} value={exist ? exist.value : ""} onChange={handleChange}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </>    
                    }

                    <button type="submit" className="col-12 col-md-3 btn btn-success"><i className="fa fa-save"></i> Guardar</button>
                    {oldData.id &&
                        <button type="button" className="col-12 ml-md-2 col-md-3 btn btn-danger" onClick={delete_plan}>Eliminar</button>
                    }
                    <button type="button" className="col-12 ml-md-2 col-md-3 btn btn-info" onClick={() => recharge()}>Cancelar</button>

                </div>
            </form>
        </Modal>
    )
}
