
import React, { useEffect, useState } from 'react'
import requests from '../../requests';
import Modal from '../UI/Modal';
import moment from 'moment-timezone';

import helper from '../helper';
const { t } = helper;

export const ClassModal = ({ class_id, teacher_id, recharge }) => {
    const [clase, setclase] = useState({})
    const [futils, setfutils] = useState()

    const get_class = async () => {
        let req = new requests({
            name: 'classes',
            extraUrl: `/${teacher_id}/${class_id}`
        })

        try {
            const q = await req.prepareConfig().get();
            if(q.Data && q.Data[0]) {
                setclase({ ...q.Data[0] })
            }
        } catch (e) {
            console.error(e);
        }
    }

    const get_futils = async () => {
        let req = new requests({
            name: 'futils',
        })

        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                setfutils({ ...q.Data })
            }
        } catch (e) {
            console.error(e);
        }
    }

    const get_difficulty = (level) => {
        // Buscamos el nivel
        let data_return = "Sin nivel";

        const search_level = futils?.difficulty.find(e => e[0] == level);
        if(search_level) {
            data_return = search_level[1] || "Sin nivel";
        }

        return data_return;
    }

    const close_modal = (e) => {
        e.preventDefault();
        recharge();
    }

    useEffect(get_class, []);
    useEffect(get_futils, []);

    return (
        <Modal title="Detalles de clase" open={true} className="modal_plans">
                <div className="form-body">
                    <div className="row p-t-20">

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="id">ID: </label>
                                <input required type="text" id="id" className="form-control" value={clase.id} disabled/>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">Nombre: </label>
                                <input required type="text" id="name" className="form-control" value={clase.name} disabled/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="price">Precio: </label>
                                <input required type="text" id="price" className="form-control" value={clase.price} disabled/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="sport">Deporte: </label>
                                <input required type="text" id="sport" className="form-control" value={clase.sport?.name} disabled/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="description">Descripción: </label>
                                <input required type="text" id="description" className="form-control" value={clase.description} disabled/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="id">Estado: </label>
                                <input required type="text" id="id" className="form-control" value={t(clase.status)} disabled/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="date">Fecha: </label>
                                <input required type="text" id="date" className="form-control" value={moment(clase.date).format("DD/MM/YY HH:mm:ss")} disabled/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="createdAt">Fecha de creación: </label>
                                <input required type="text" id="createdAt" className="form-control" value={moment(clase.createdAt).format("DD/MM/YY HH:mm:ss")} disabled/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="updatedAt">Fecha de ultima modificación: </label>
                                <input required type="text" id="updatedAt" className="form-control" value={moment(clase.updatedAt).format("DD/MM/YY HH:mm:ss")} disabled/>
                            </div>
                        </div>
                    </div>

                    <div className="row p-t-20 p-b-20">
                        <div className="col-12">
                            <h6>Disponibilidad de usuarios: <strong>{clase.availability}</strong></h6>
                            <h6>Dificultad expresada: <strong>{get_difficulty(clase.difficulty)}</strong></h6>
                        </div>
                        <hr />
                        <h3 className="col-12">Ubicación</h3>
                        <div className="col-12">
                            <h6>Dirección: <strong>{clase.location?.address.replace(/\+/g, " ")}</strong></h6>
                            <h6>Latitud: <strong>{clase.location?.lat}</strong></h6>
                            <h6>Longitud: <strong>{clase.location?.lng}</strong></h6>
                            <h6>Datos extras: <strong>{clase.location?.address_extra}</strong></h6>
                        </div>
                        <hr />
                        <h3 className="col-12">Tags</h3>
                        <div className="col-12">
                            {clase?.tags?.map((v, i) => {
                                return (
                                    <span key={i + v} className="badge badge-primary mr-1">{v}</span>
                                )
                            })}
                        </div>
                        <hr />
                        <h3 className="col-12">Requerimientos de la clase</h3>
                        <div className="col-12">
                            {clase?.requirements?.map((v, i) => {
                                return (
                                    <span key={i + v} className="badge badge-primary mr-1">{v}</span>
                                )
                            })}
                        </div>
                        <hr />
                        <h3 className="col-12">Metodos de pago</h3>
                        <div className="col-12">
                            {clase?.payments_methods?.map((v, i) => {
                                return (
                                    <span key={i + v} className="badge badge-primary mr-1">{v}</span>
                                )
                            })}
                        </div>
                    </div>
                    <button type="button" className="col-12 btn btn-info" onClick={close_modal}>Cerrar</button>

                </div>
        </Modal>
    )
}
