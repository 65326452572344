import Home from "../Admin/Home/Home";

// Users
import Clients from "../Admin/Clients/Clients";
import ModifyClient from "../Admin/Clients/ModifyClient";

// Plans
import Plans from "../Admin/Plans/Plans";
import PlanLevels from "../Admin/Plans/Plans_levels";

// Sports
import Sports from "../Admin/Sports/Sports";

const MainRoutes = [
    {path: '/home', name: 'Home', component: Home},
    {path: '/clients', name: 'Clients', component: Clients},
    {path: '/clients/:id', name: 'ModifyClient', component: ModifyClient},
    {path: '/plans', name: 'Plans', component: Plans},
    {path: '/plan_levels', name: 'PlanLevels', component: PlanLevels},
    {path: '/sports', name: 'PlanLevels', component: Sports},
]

const indexRoutes = [
    ...MainRoutes
];

export default indexRoutes;