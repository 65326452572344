import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import momentjs from 'moment';
import helper from '../helper';

const { t, capitalizeFirstLetter } = helper;

export default class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: null,
            users: [],
            not_more_users: false,
            search_params: { offset: 0, limit: 50, orderBy: 'createdAt', order: 'ASC'}
        }
    }


    get_users = async () => {
        // Hacemos el request 
        let req = new requests({
            name: "clients",
            query: this.state.search_params
        })

        try {
            let q = await req.prepareConfig().get();
            if(q.Success && q.Data && q.Data.length > 0) {
                let { search_params } = this.state;
                search_params.offset = q.Data.length;
                let users = [...this.state.users,...q.Data];
                this.setState({ users, search_params, not_more_users: false });
            } else if(q.Success) {
                this.setState({ not_more_users: true});
            }
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    componentDidMount = async () => {
        await this.get_users();

        document.title = helper.get_title('Clientes');
    }

    editUser = (id) => {
        if(id) {
            this.setState({ redirect: `/clients/${id}` })
        } 
    }

    buscar = async (e) => {
        let { value } = e.target
        let { search_params } = this.state;
    
        search_params.q = value;
        search_params.offset = 0;

        let req = new requests({
            name: "clients",
            query: search_params
        })


        try {
            let q = await req.prepareConfig().get();
            if(q.Success && q.Data && q.Data.length > 0) {
                let { search_params } = this.state;
                search_params.offset = q.Data.length;
                this.setState({ users: q.Data, search_params, not_more_users: false });
            } else if(q.Success && !q.Data) {
                this.setState({ users: [] ,not_more_users: true});
            }
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    render() {        
        const { loading, redirect, users } = this.state;

        return (
            <Main loading={loading} redirect={redirect} permission={{module: "users", action: "view"}}>
                <SectionTitle 
                    title="Usuarios"
                    breadcrumb={[{name: "Home", redirect: '/'},{name: 'Clientes'}]}
                    button={[]}/>

                <div className='row'>
                    <div className="col-12">
                        <Card>
                            <div className="card-header bg-dark">
                                <h4 className="m-b-0 text-white">Clientes - {users?.length}</h4>
                            </div>
                            <CardBody>
                                <div className="dataTables_filter mb-2 col-4">
                                    <label htmlFor="search" className="col-12">Search: </label>
                                    <input id='search' type="text" className="form-control form-control-sm" onChange={this.buscar}/>
                                </div>
                                <table
                                        className="display nowrap table table-hover table-striped table-bordered"
                                        >
                                        <thead>
                                            <tr>
                                                <th>RUT</th>
                                                <th>Nombre y Apellido</th>
                                                <th>Email</th>
                                                <th>Rol</th>
                                                <th>Creación</th>
                                                <th>Estado</th>
                                                <th>Ver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users &&
                                                users.map((v, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{v.rut}</td>
                                                            <td>{v.name} {v.last_name}</td>
                                                            <td>{v.email}</td>
                                                            <td>
                                                                {v.role === 'teacher' && <div className="label label-table label-info">{capitalizeFirstLetter(t(v.role))}</div>}
                                                                {v.role !== 'teacher' && <div className="label label-table label-primary">{capitalizeFirstLetter(t(v.role))}</div>}
                                                            </td>
                                                            
                                                            <td>{momentjs(v.createdAt).format('DD/MM/YYYY - HH:mm')}</td>
                                                            {/* <td>
                                                                {v.verified &&
                                                                    <div className="label label-table label-success">Verified</div>
                                                                }
                                                                {!v.verified &&
                                                                    
                                                                    <div className="label label-table label-danger">Not Verified</div>
                                                                }
                                                            </td> */}
                                                            <td>
                                                                {v.active &&
                                                                    <div className="label label-table label-success">Activo</div>
                                                                }
                                                                {!v.active &&
                                                                    
                                                                    <div className="label label-table label-danger">Inactivo</div>
                                                                }
                                                            </td>
     
                                                            <td>
                                                                <button className="btn btn-outline-dark btn-xs" onClick={() => this.editUser(v.id)}><i className="ti-eye"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                            }

                                        </tbody>
                                    </table>
                                    <button type="button" className="btn btn-info float-right col-12 col-md-2" onClick={this.get_users}>Ver Mas</button>
                                    {this.state.not_more_users &&
                                    <div className="row">
                                        <div className="col-6 alert alert-danger">No hay mas usuarios para mostrar</div>
                                    </div>
                                    }
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Main>
        )
    }
}
