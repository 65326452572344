import React, { Component } from 'react';
import './Login.css';
import { Redirect } from 'react-router-dom';
import requests from '../../requests';
import helper from '../helper';
export default class Login extends Component {
	constructor(props) {
        super(props);
        this.state = {
            user: '',
            pass: '',
            alert: null,
            redirect: null,
            remember: false
        }
    }

    componentDidMount() {
        document.title = helper.get_title('Admin');

        // Comprobamos si hay datos guardados del usuario
        let udata = localStorage.getItem('@user_remember');
        udata = JSON.parse(udata);
        if(udata) {
            const { user, pass } = udata;
            this.setState({ user, pass });
        }
    }
    
	handleClick = (e) => {
        e.preventDefault();
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
    }
    
    login = async (e) => {
        e.preventDefault();
        sessionStorage.clear();
        let req = new requests({
            name: "login",
            extraUrl: "/admin",
            body: { email: this.state.user, password: this.state.pass }
        })
        try {
            let q = await req.prepareConfig().post();
            const { Success, Message } = q;
            if(!Success) {
                this.setState({ alert: Message, user: '', pass: '' });
            } else {           
                if(this.state.remember) {
                    // Guardamos los datos del usuario para el login
                    localStorage.setItem('@user_remember', JSON.stringify({ user: this.state.user, pass: this.state.pass }));
                }
                this.setState({ redirect: '/home' });
            }
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        this.setState({ [id] : value })
    }

	render() {

        const { alert, user, pass, remember } = this.state;

        if(this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        } else if(requests.getUserData()) {
            return <Redirect to='/home' />
        }

		return (
            <section id="wrapper" className="login-register login-sidebar" >
                <div className="login-box card">
                    <div className="card-body">
                        {alert &&
                            <div className="alert alert-danger">{alert}</div>
                        }
                        
                        <form className="form-horizontal form-material text-center" id="loginform" onSubmit={this.login}>
                            <div className="form-group m-t-40">
                                <div className="col-xs-12">
                                    <input className="form-control" type="text" id="user" value={user} required placeholder="Username" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <input className="form-control" id="pass" type="password" value={pass} required placeholder="Password" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <div className="d-flex no-block align-items-center">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" checked={remember} onClick={() => this.setState({ remember: !remember })} className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">Recordarme</label>
                                        </div> 
                                        <div className="ml-auto">
                                            <a href="/#" id="to-recover" onClick={this.handleClick} className="text-muted"><i className="fas fa-lock m-r-5"></i> Recuperar contraseña</a> 
                                        </div>
                                    </div>   
                                </div>
                            </div>
                            <div className="form-group text-center m-t-20">
                                <div className="col-xs-12">
                                    <button className="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Iniciar sesión</button>
                                </div>
                            </div>
                        </form>
                        <form className="form-horizontal" id="recoverform">
                            <div className="form-group ">
                                <div className="col-xs-12">
                                    <h3>Recuperar Contraseña</h3>
                                    <p className="text-muted">Enter your Email and instructions will be sent to you! </p>
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="col-xs-12">
                                    <input className="form-control" type="text" required="" placeholder="Email" />
                                </div>
                            </div>
                            <div className="form-group text-center m-t-20">
                                <div className="col-xs-12">
                                    <button className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
	}
}
