import React, { useState } from 'react'
import swal from 'sweetalert';
import requests from '../../requests';

import Modal from '../UI/Modal';

export const ModalSport = ({oldData = {}, recharge }) => {
    const [sport, setsport] = useState(oldData)
    

    const handleChange = (e) => {
        const { value, id } = e.target;

        let sport_modify = sport;

        sport_modify[id] = value;
        setsport({...sport_modify});
    }

    const delete_sport = async (e) => {
        e.preventDefault();
        const { id } = sport;
        if(!id) return;
        swal({
            title: "¿Estas seguro?",
            text: "Estas por eliminar un deporte",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                let req = new requests({
                    name: "plans",
                    extraUrl: `/sports/${id}`
                })

                try {
                    let q = await req.prepareConfig().delete();
                    if(q.Success) {
                        swal('Excelente', 'Deporte eliminado exitosamente', 'success').then(() => {
                            recharge();
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e)
                }
            }
        })
    }

    const send = async (e) => {
        e.preventDefault();
        const { id, name, description } = sport;
        if(!name) return;

        let req = new requests({
            name: 'plans',
            extraUrl: '/sports',
            body: { name, description }
        })

        try {
            if(id) {
                // Entonces editamos
                req.extraUrl += `/${id}`;
                const q = await req.prepareConfig().put();
                if(q.Success) {
                    swal('Excelente', "Deporte modificado exitosamente", 'success').then(() => {
                        recharge();
                    })
                } else {
                    swal('Error', q.Message, 'error').then(() => {
                        recharge();
                    })
                }
            } else {
                // Entonces creamos uno nuevo
                const q = await req.prepareConfig().post();
                if(q.Success) {
                    swal('Excelente', "Deporte agregado exitosamente", 'success').then(() => {
                        recharge();
                    })
                } else {
                    swal('Error', q.Message, 'error').then(() => {
                        recharge();
                    })
                }
            }
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error').then(() => {
                recharge();
            })
        }
    }

    return (
        <Modal title={oldData.id ? 'Modificar deporte' : 'Nuevo deporte'} open={true}>
            <form onSubmit={send}>
                <div className="form-body">
                    <div className="row p-t-20">

                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="id">ID: </label>
                                <input required type="text" id="" className="form-control" value={sport.id} disabled/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">Nombre: </label>
                                <input required type="text" id="name" className="form-control" value={sport.name} onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label" htmlFor="description">Descripción: </label>
                                <input required type="text" id="description" className="form-control" value={sport.description} onChange={handleChange}/>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="col-12 col-md-3 btn btn-success"><i className="fa fa-save"></i> Guardar</button>
                    {oldData.id &&
                        <button type="button" className="col-12 ml-md-2 col-md-3 btn btn-danger" onClick={delete_sport}>Eliminar</button>
                    }
                    <button type="button" className="col-12 ml-md-2 col-md-3 btn btn-info" onClick={() => recharge()}>Cancelar</button>

                </div>
            </form>
        </Modal>
    )
}
