import React, { Component } from 'react';
import './App.css';
import './index.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './Admin/Login/Login'
import ValidateUser from './Admin/Login/ValidateUser';
import Routes from './Routes/index';
import { ToastContainer } from 'react-toastify';

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		return (
			<>
			<ToastContainer />
			<BrowserRouter >
					<Switch>
						<Route exact path="/validateUser" component={ValidateUser} />
						{
							Routes.map((prop, key) => {
								return <Route key={key} path={prop.path} name={prop.name} component={prop.component} exact />
							})
						}
						<Route exact path="/" component={Login} />
						<Route exact path="/*" component={Login} />

					</Switch>
			</BrowserRouter>
		
			
			</>
		);

	}
}

