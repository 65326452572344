import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import helper from '../helper';
import { ModalPlans } from './PlansModal';

const { ms_to_day } = helper;
export default class Plans extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: null,
            plans: [],
            not_more_plans: false,
            search_params: { offset: 0, limit: 50, orderBy: 'createdAt', order: 'ASC'}
        }
    }


    get_plans = async () => {
        // Hacemos el request 
        let req = new requests({
            name: "plans",
            query: this.state.search_params
        })

        try {
            let q = await req.prepareConfig().get();
            if(q.Success && q.Data && q.Data.length > 0) {
                let { search_params } = this.state;
                search_params.offset = q.Data.length;
                let plans = [...this.state.plans,...q.Data];
                this.setState({ plans, search_params, not_more_plans: false });
            } else if(q.Success) {
                this.setState({ not_more_plans: true});
            }
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    componentDidMount = async () => {
        await this.get_plans();

        document.title = helper.get_title('Planes');
    }

    editPlan = (id) => {
        // Buscamos el deporte
        let { plans } = this.state;
        if(!id) return;

        const plan = plans.find(elem => elem.id === id);

        if(plan) {
            this.setState({ 
                open_modal: true,
                data_modify: plan
            })
        }
    }

    buscar = async (e) => {
        let { value } = e.target
        let { search_params } = this.state;
    
        search_params.q = value;
        search_params.offset = 0;

        let req = new requests({
            name: "plans",
            query: search_params
        })


        try {
            let q = await req.prepareConfig().get();
            if(q.Success && q.Data && q.Data.length > 0) {
                let { search_params } = this.state;
                search_params.offset = q.Data.length;
                this.setState({ plans: q.Data, search_params, not_more_plans: false });
            } else if(q.Success && !q.Data) {
                this.setState({ plans: [] ,not_more_plans: true});
            }
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    addPlan = () => {
        this.setState({
            open_modal: true,
            data_modify: {}
        })
    }

    closeModal = () => {
        this.setState({ 
            open_modal: false,
            data_modify: {}
        });

        window.location.reload(window.location.href);
    }

    render() {        
        const { loading, redirect, plans, open_modal, data_modify } = this.state;

        return (
            <Main loading={loading} redirect={redirect} permission={{module: "plans", action: "view"}}>
                <SectionTitle 
                    title="Usuarios"
                    breadcrumb={[{name: "Home", redirect: '/'},{name: 'Planes'}]}
                    button={[
                        { name: "Nuevo", icon: 'ti-plus', onClick: this.addPlan }
                    ]}/>

                <div className='row'>
                    <div className="col-12">
                        <Card>
                            {open_modal &&
                                <ModalPlans oldData={data_modify} recharge={this.closeModal}/>
                            }
                            <div className="card-header bg-dark">
                                <h4 className="m-b-0 text-white">Planes - {plans?.length}</h4>
                            </div>
                            <CardBody>
                                <div className="dataTables_filter mb-2 col-4">
                                    <label htmlFor="search" className="col-12">Search: </label>
                                    <input id='search' type="text" className="form-control form-control-sm" onChange={this.buscar}/>
                                </div>
                                <table
                                        className="display nowrap table table-hover table-striped table-bordered"
                                        >
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Duración</th>
                                                <th>Precio</th>
                                                <th>Cant. Servicios</th>
                                                <th>Veces contratado</th>
                                                <th>Estado</th>
                                                <th>Ver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {plans &&
                                                plans.map((v, i) => {
                                                    return (
                                                        <tr key={v.id}>
                                                            <td>{v.name}</td>
                                                            <td>{v.duration} ({ms_to_day(v.duration)})</td>
                                                            <td>$ {v.price}</td>
                                                            <td>{v.services.length}</td>
                                                            <td>{v.count_contracts}</td>
                                                            <td>
                                                                {v.active ?
                                                                    <div className="label label-table label-success">Activo</div>
                                                                    :
                                                                    <div className="label label-table label-danger">Inactivo</div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-outline-dark btn-xs" onClick={() => this.editPlan(v.id)}><i className="ti-eye"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                            }

                                        </tbody>
                                    </table>
                                    <button type="button" className="btn btn-info float-right col-12 col-md-2" onClick={this.get_users}>Ver Mas</button>
                                    {this.state.not_more_plans &&
                                    <div className="row">
                                        <div className="col-6 alert alert-danger">No hay mas usuarios para mostrar</div>
                                    </div>
                                    }
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Main>
        )
    }
}
