import Axios from "axios";
import swal from "sweetalert";
import routes from './routes.json';

export default class requests {
    constructor(object) {
        const { form, name, body, extraUrl, query, headers, route } = object;
        this.name       = name      || false;
        this.body       = body      || false;
        this.form       = form      || false;
        this.token      = false;
        this.extraUrl   = extraUrl  || '';
        this.query      = query     || false;
        this.headers    = headers   || {};
        this.route      = route     || this.getRoute();
        this.config     = {};
        this.reload     = true;
    }

    prepareConfig() {
        this.getToken();
        if(this.token){
            this.headers = {
                ...this.headers,
                authorization: `Bearer ${this.token}`
            }
        }

        const notReloadPages = ['login']
        if(notReloadPages.includes(this.name)) {this.reload = false}

        if(Object.keys(this.body).length > 0){
            this.config = this.body;
        }

        if(this.form && !this.body) {
            this.config = this.form;
        }

        this.route += this.extraUrl;

        if(Object.keys(this.query).length > 0) {
            let query = "";
            for(let i in this.query) {
                if(query === ""){
                    query = `?${i}=${this.query[i]}`;
                } else {
                    query += `&${i}=${this.query[i]}`;
                }
            }

            this.route += query;
        }
        return this;
    }

    async get() {
        return Axios.get(this.route, { ...this.config, headers: this.headers}).then(response => {
            this.token = response;
            this.saveToken();
            return response.data;
        }).catch(e => {
            if(!e?.response?.data) {
                swal('Error', 'Error al comunicarse con la API', 'error').then(() => {
                    requests.clearData();
                })
                return;
            }
            const { HttpCodeResponse, loggedUser } = e.response.data;
            if(HttpCodeResponse !== 401) {
                // Guardamos el token
                if(loggedUser) {
                    this.token = e.response;
                    this.saveToken()
                } else if(Object.keys(this.headers).length > 0){ requests.clearData(); }
            } else {
                requests.clearData();
            }
            return e.response.data
        })
    }

    async post () {
        return Axios.post(this.route, this.config, {headers: this.headers}).then(response => {
            this.token = response;
            this.saveToken();
            return response.data;
        }).catch(e => {
            if(!e?.response?.data) {
                swal('Error', 'Error al comunicarse con la API', 'error').then(() => {
                    requests.clearData();
                })
                return;
            }
            console.log(e);
            const { HttpCodeResponse,loggedUser } = e.response.data;
            if(HttpCodeResponse !== 401) {
                // Guardamos el token
                if(loggedUser) {
                    this.token = e.response;
                    this.saveToken()
                }else if(Object.keys(this.headers).length > 0) { requests.clearData(); }
            } else {
                requests.clearData();
            }

            return e.response.data
        })
    }

    async delete () {
        return Axios.delete(this.route, {headers: this.headers}).then(response => {
            this.token = response;
            this.saveToken();
            return response.data;
        }).catch(e => {
            if(!e?.response?.data) {
                swal('Error', 'Error al comunicarse con la API', 'error').then(() => {
                    requests.clearData();
                })
                return;
            }
            const { HttpCodeResponse,loggedUser } = e.response.data;
            if(HttpCodeResponse !== 401) {
                // Guardamos el token
                if(loggedUser) {
                    this.token = e.response;
                    this.saveToken()
                }else { requests.clearData(); }
            }else {
                requests.clearData();
            }

            return e.response.data
        })
    }

    async put () {
        return Axios.put(this.route, this.config, {headers: this.headers}).then(response => {
            this.token = response;
            this.saveToken();
            return response.data;
        }).catch(e => {
            if(!e?.response?.data) {
                swal('Error', 'Error al comunicarse con la API', 'error').then(() => {
                    requests.clearData();
                })
                return;
            }
            const { HttpCodeResponse,loggedUser } = e.response.data;
            if(HttpCodeResponse !== 401) {
                // Guardamos el token
                if(loggedUser) {
                    this.token = e.response;
                    this.saveToken()
                }else { requests.clearData(); }
            }else {
                requests.clearData();
            }

            return e.response.data
        })
    }

    saveToken () {
        const { loggedUser } = this.token?.data;
        if(loggedUser) {
            localStorage.setItem("token", loggedUser.token);
            this.setUserData(loggedUser);
            return true;
        } else if(Object.keys(this.headers).length > 0){ 
            requests.clearData(); 
        }
    }

    getToken () {
        if(this.token){
            this.token = localStorage.getItem("token");
            return true;
        }
    }

    static clearData () {
        localStorage.setItem('userData', '');
        localStorage.setItem('token', '');
        const udata = localStorage.getItem('@user_remember');
        localStorage.clear();
        localStorage.setItem('@user_remember', udata);
        window.location.replace(window.location.origin + '/');
        if(this.reload !== false) {
        }
        return true;
    }

    static getUserData () {
        let data = localStorage.getItem('userData');
        data = JSON.parse(data);
        return data;
    }

    setUserData = (data) => {
        data = JSON.stringify(data);
        localStorage.setItem('userData', data);
        return true;
    }

    getRoute () {
        if(!this.name) throw new Error('Ruta no especificada')
        let routeReturn = routes.main;
        
        if(process.env.REACT_APP_ENV_ROUTES === 'dev') {
            routeReturn = routes.dev;
        } 
        let c = routes.routes[this.name];
        if(c) {
            routeReturn += c.allRoute === true ? `${routes.version}${c.path}` : `${c.path}`;
            this.token = c.token;
        }
        return c ? routeReturn : false;
    }
}
