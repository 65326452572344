import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import swal from 'sweetalert';
import Switch from '@material-ui/core/Switch';
import helper from '../helper';

import moment from 'moment-timezone';
import { ClassModal } from './ClassModal';
const { t,ms_to_day } = helper;

export default class ModifyClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: null,
            Client: {},
            futils: {},
            classes: [],
            contracts: [],
            modal_classes: null
        }
    }

    cancel = (e) => {
        e.preventDefault()
        this.setState({ redirect: '/Clients' })
    }

    openModal = async (e) => {
        e.preventDefault();
        const { id } = this.props.match.params;
        swal({
            title: "¿Estas seguro?",
            text: "Estas por eliminar un usuario!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                let req = new requests({
                    name: "Clients",
                    extraUrl: `/${id}`
                })

                try {
                    let q = await req.prepareConfig().delete();
                    if(q.Success) {
                        swal('Excelente', 'Usuario eliminado exitosamente', 'success').then(() => {
                            this.setState({ redirect: '/Clients' })
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e)
                }
            }
        })
    }

    checkRegEx = (value, type) => {
        if(!value || !type) return false;
        let regEx;
        switch(type){
            case 'text':
                regEx = /^[A-Za-z ]{1,20}$/i;
                break;
            case 'number': 
                regEx = /^[0-9]{1,12}$/;
            break;
            case 'rut':
                regEx = /^([0-9]{1,8})+(-)+([a-z0-9]{1})$/i;
            break;
            case 'email': 
                regEx = /^([A-Za-z0-9._-]{1,20})+@+([a-z]{1,15})+(\.[a-z]{1,4})+(\.[a-z]{1,3})?$/;    
            break;
            default:
                return true;
        }

        let c = new RegExp(regEx)
        if(c.test(value)) return true;
        else return false;
    }

    handleChange = (e) => {
        let { value, id, type, parentNode, name } = e.target;
        let { Client } = this.state;

        if(type === 'select-one'){
            Client[id] = value;
            this.setState({ Client })
        } else if(type === 'checkbox') {
            let [ name_field, comuna ] = id.split('|');
            if(name_field !== 'comuna') return;
            comuna = parseInt(comuna);

            if(!Client[name_field]) {
                Client[name_field]= [];
            }

            if(Client[name_field].includes(comuna)) {
                // Eliminamos la comuna
                Client[name_field] = Client[name_field].filter(e => e !== comuna);
            } else {
                // La agregamos
                Client[name_field].push(comuna);
            }

            this.setState({ Client });
        } else {
            // has-danger
            if(name && name === "rut") {
                type = "rut"
            }
            let error = !this.checkRegEx(value, type);

            // this.setState({ [id]: value })
            if(error) {
                document.getElementById(id).classList.add('form-control-danger')
                parentNode.classList.add('has-danger');
            } else {
                document.getElementById(id).classList.remove('form-control-danger')
                parentNode.classList.remove('has-danger');
                Client[id] = value;
                this.setState({ Client })
            }
        }
    }

    verify_teacher = async (e) => {
        this.setState({ loading: true })
        e.preventDefault();
        const { id } = this.props.match.params;

        let req = new requests({
            name: 'clients',
            extraUrl: `/${id}/verify`,
            body: { type: 1 }
        })

        try {
            let q = await req.prepareConfig().put();
            this.setState({ loading: false })
            if(q.Success) {
                swal('Excelente', 'Usuario verificado exitosamente', 'success').then(() => {
                    this.setState({ redirect: '/clients' })
                })
            } else {
                swal('Error', q.Message, 'error')
            }
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true });
        
        try {
            const futils_obj = new requests({ name: "futils" });
            const futils_q  = await futils_obj.prepareConfig().get();

            let futils = {};

            if(futils_q.Success) {
                futils = futils_q.Data;
            }
            this.setState({ futils, loading: false });
        } catch (e) {
            console.log(e);
            this.setState({ loading: false });
        }

        const { id } = this.props.match.params;
        if(id) {
        //     // Hacemos el request
            let req = new requests({
                name: "clients",
                extraUrl: `/${id}`
            })
            try {
                let q = await req.prepareConfig().get();
                if(q.Data.length === 0) {
                    swal('Error', "Error al obtener el cliente", 'error').then(() => {
                        this.setState({ redirect: '/clients' })
                    })
                } else {
                    let Client = q.Data[0]
                    this.setState({
                        loading: false,
                        Client
                    })
                }
            } catch (e) {
                console.error(e)
            }
        } else {
            this.setState({redirect: '/clients'})

        }
        this.get_classes();
        this.get_contracts();
    }

    get_contracts = async () => {
        this.setState({ loading: true })
        const { id } = this.props.match.params;
        let req = new requests({
            name: 'plans',
            query: { teacher_id: id },
            extraUrl: '/contract'
        })

        try {
            let q = await req.prepareConfig().get();

            this.setState({ loading: false })

            if(q.Data) {
                this.setState({ contracts: q.Data })
            }
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    downloadFile = (file_id) => {
        if(!file_id) return;

        let req = new requests({
            name: 'files',
            extraUrl: `/public/${file_id}`,
        })

        // Obtenemos la URL
        const URL = req.route + req.extraUrl;
        window.open(URL);
    }

    modify = async (e) => {
        this.setState({ loading: true })
        e.preventDefault();
        const { id } = this.props.match.params;
        let sendData = {}
        for(let s in this.state.Client) {
            if(s === 'email' || s === 'dni' || s === 'verified' || s === 'active') continue;
            if(this.state.Client[s] === '-' || this.state.Client[s] === '') continue;

            sendData[s] = this.state.Client[s];
        }
        console.log(sendData)


        let req = new requests({
            name: 'clients',
            extraUrl: `/${id}`,
            body: sendData
        })

        try {
            let q = await req.prepareConfig().put();
            this.setState({ loading: false })
            if(q.Success) {
                swal('Excelente', 'Usuario modificado exitosamente', 'success').then(() => {
                    this.setState({ redirect: '/Clients' })
                })
            } else {
                swal('Error', q.Message, 'error')
            }
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    get_classes = async () => {
        this.setState({ loading: true })
        const { id } = this.props.match.params;
        let req = new requests({
            name: 'classes',
            extraUrl: `/${id}`
        })

        try {
            let q = await req.prepareConfig().get();
            this.setState({ loading: false })

            if(q.Data) {
                this.setState({ classes: q.Data })
            }
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    viewClass = (id) => {
        this.setState({
            modal_classes: id
        })
    }

    closeModalClasses = () => {
        window.location.reload(window.location.href);
    }

    openContract = (contract) => {
        let text = "";

        const text_to_arm = [
            `Fecha de contratacion: ${moment(contract.ceratedAt).format('DD/MM/Y hh:mm:ss')}`,
            `Duración del plan: ${(ms_to_day(contract.plan_information?.duration) || 0)}`,
            `Plan Contratado: ${contract.plan_information.name}`,
            `Estado de contratación: ${t(contract.status)}`,
            `Valor del plan: $ ${contract.plan_information?.price}`,
            `Valor abonado: $ ${contract.amount}`,
            `Estado del pago: ${contract.payment[0]?.status}`,
            `Fecha de transacción: ${moment(contract?.payment[0]?.transaction_date).format('DD/MM/Y hh:mm:ss')}`
        ]

        text = text_to_arm.join('\n');

        swal({
            title: "Contratación",
            text
        })
    }

    toggleActive = async (e) => {
        const { id } = this.props.match.params;

        const { checked } = e.target;

        let req = new requests({
            name: 'clients',
            extraUrl: `/${id}`,
            body: {active: checked }
        })

        try {
            let q = await req.prepareConfig().put();
            if(q.Success) {
                this.componentDidMount()
            } else {
                swal('Error', 'Error al cambiar el estado del usuario', 'error').then(() => {
                    this.componentDidMount();
                })
            }
        } catch(e) {
            console.error('Error: ', e)
        }
    }

    getProfileImage = () => {
        if(this.state.Client && this.state.Client.image) {
            let req = new requests({
                name: 'files',
                extraUrl: `/public/${this.state.Client.image}`,
            })

            // Obtenemos la URL
            const URL = req.route + req.extraUrl;
            return URL
        }
    }

    render() {
        const { loading, Client, redirect, futils, classes, modal_classes, contracts } = this.state;
        return (
            <Main loading={loading} redirect={redirect} footer='position-fixed'>
                <SectionTitle 
                    title="Modificar cliente"
                    breadcrumb={[
                        {name: 'Home', redirect: '/'},
                        {name: 'Clients', redirect: '/clients'},
                        {name: 'Modify Client'}
                    ]}
                />
                <div className="row">
                    <div className="col-12">
                        {Client &&
                            <>
                                <Card>
                                    <div className="card-header bg-info">
                                    <h4 className="m-b-0 text-white">{Client.name} {Client.last_name} - {helper.capitalizeFirstLetter(helper.t(Client.role))}</h4>
                                    </div>
                                    <CardBody>
                                        {modal_classes &&
                                            <ClassModal class_id={modal_classes} recharge={this.closeModalClasses} teacher_id={this.props.match.id}/>
                                        }
                                        <form onSubmit={this.modify}>
                                            <div className="form-body">
                                                <div className="row p-t-20">
                                                    <div className="profile_image col-md-6 col-12">
                                                        <article>
                                                            <span style={{ backgroundImage: `url("${this.getProfileImage()}")` }} ></span>
                                                        </article>
                                                    </div>
                                                    <div className="offset-md-5 col-12 col-md-1">
                                                        <h6>Activo</h6>
                                                        <Switch
                                                            disabled={!helper.checkPermission('clients', 'toggle_active')}
                                                            checked={!!Client.active}
                                                            onChange={this.toggleActive}
                                                            color="primary"
                                                            name="activeClient"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row p-t-20">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="control-label" htmlFor="name">Nombre: *</label>
                                                            <input required type="text" id="name" className="form-control" defaultValue={Client.name} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="lastname">Apellido: *</label>
                                                            <input required type="text" id="last_name" className="form-control" defaultValue={Client.last_name} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="rut">RUT: *</label>
                                                            <input disabled className="form-control" defaultValue={Client.rut} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="control-label" htmlFor="name">Genero:</label>
                                                            <select className="form-control custom-select" value={Client.gender} id="gender" onChange={this.handleChange}>
                                                                <option value="-">Selecciona...</option>
                                                                {futils?.genders?.map((v, i) => {
                                                                    return <option key={i} value={v}>{v}</option>
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="email">Rol</label>
                                                            <input disabled className="form-control" value={Client.role} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="email">Email: *</label>
                                                            <input disabled className="form-control" defaultValue={Client.email} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="password">Password: </label>
                                                            <input type="password" id="password" className="form-control" placeholder="password" onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group ">
                                                        <label className="control-label" htmlFor="email">Followers</label>
                                                        <input disabled className="form-control" defaultValue={Client.followers} />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group ">
                                                        <label className="control-label" htmlFor="email">Follows</label>
                                                        <input disabled className="form-control" defaultValue={Client.follows} />
                                                    </div>
                                                </div>

                                                {Client?.role === 'teacher' ?
                                                    <>
                                                        <div className="col-md-2">
                                                            <div className="form-group ">
                                                                <label className="control-label" htmlFor="email">Level Name</label>
                                                                <input disabled className="form-control" defaultValue={Client?.level?.name} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group ">
                                                                <label className="control-label" htmlFor="email">Points (QR)</label>
                                                                <input disabled className="form-control" defaultValue={Client?.level?.points} />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-md-2">
                                                            <div className="form-group ">
                                                                <label className="control-label" htmlFor="email">Clases contratadas</label>
                                                                <input disabled className="form-control" defaultValue={Client.clases_obtenidas} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group ">
                                                                <label className="control-label" htmlFor="email">Clases asistidas</label>
                                                                <input disabled className="form-control" defaultValue={Client.clases_asistidas} />
                                                            </div>
                                                        </div>   
                                                    </>
                                                }

                                                <div className="col-md-4">
                                                    <div className="form-group ">
                                                        <label className="control-label" htmlFor="phone">Teléfono: </label>
                                                        <input type="number" id="phone" className="form-control" value={Client?.phone} onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                            </div>

                                            {Client?.role === 'teacher' &&
                                                <div>
                                                    <h4>VERIFICATION <span className={`badge ${Client.verified_teacher ? 'badge-success' : 'badge-danger'}`}>{Client.verified_teacher ? "Verificado" : "No Verificado"}</span></h4>

                                                    <div className="row">
                                                        <table className="display nowrap table table-hover table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <td>#</td>
                                                                    <td>Fecha de subida</td>
                                                                    <td>Acción</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Client.verified_files?.map(v => {
                                                                    return (
                                                                        <tr key={v._id}>
                                                                            <td>{v.file_id}</td>
                                                                            <td>{moment(v.uploadAt).format('DD/MM/Y hh:mm:ss')}</td>
                                                                            <td>
                                                                            <button type="button" className="btn btn-outline-dark btn-xs" onClick={() => this.downloadFile(v.file_id)}><i className="ti-cloud-down"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4>CLASES</h4>

                                                    <div className="row">
                                                        <table className="display nowrap table table-hover table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <td>Fecha</td>
                                                                    <td>Nombre</td>
                                                                    <td>Estado</td>
                                                                    <td>Precio</td>
                                                                    <td>Deporte</td>
                                                                    <td>Disponibilidad</td>
                                                                    <td>Ver</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {classes?.map(v => {
                                                                    return (
                                                                        <tr key={v.id}>
                                                                            <td>{moment(v.date).format('DD/MM/Y hh:mm:ss')}</td>
                                                                            <td>{v.name}</td>
                                                                            <td>{t(v.status)}</td>
                                                                            <td>$ {v.price}</td>
                                                                            <td>{v.sport?.name}</td>
                                                                            <td>{v.availability}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-outline-dark btn-xs" onClick={() => this.viewClass(v.id)}><i className="ti-eye"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h4>CONTRATACIONES</h4>

                                                    <div className="row">
                                                        <table className="display nowrap table table-hover table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <td>Fecha</td>
                                                                    <td>Nombre</td>
                                                                    <td>Estado</td>
                                                                    <td>Precio</td>
                                                                    <td>Servicios</td>
                                                                    <td>Duración</td>
                                                                    <td>Ver</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {contracts?.map(v => {
                                                                    return (
                                                                        <tr key={v.id}>
                                                                            <td>{moment(v.ceratedAt).format('DD/MM/Y hh:mm:ss')}</td>
                                                                            <td>{v.plan_information.name}</td>
                                                                            <td>{t(v.status)}</td>
                                                                            <td>$ {v.amount}</td>
                                                                            <td>{v.plan_information?.services?.length || 0}</td>
                                                                            <td>{(ms_to_day(v?.plan_information?.duration) || 0)}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-outline-dark btn-xs" onClick={() => this.openContract(v)}><i className="ti-eye"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }

                                            <div className="form-actions">
                                                {helper.checkPermission('clients', 'modify') &&
                                                    <button type="submit" className="col-12 col-md-3 btn btn-success"> <i className="fa fa-save"></i> Guardar</button>
                                                }
                                                <button type="button" className="ml-md-1 mt-1 mt-md-0 col-12 col-md-3 btn btn-info" onClick={this.cancel}>Cancelar</button>
                                                {helper.checkPermission('clients', 'verify_teacher') && Client.role === 'teacher' &&
                                                    <button disabled={Client.verified_teacher} type="button" className="ml-md-1 mt-1 mt-md-0 col-12 col-md-3 btn btn-danger" onClick={this.verify_teacher}>Verificar</button>

                                                }

                                            </div>
                                        </form>
                                    </CardBody>

                                </Card>
                                {/* <Card>
                                    <div className="card-header bg-info">
                                        <h4 className="m-b-0 text-white">Compañias Asignadas ({companies?.length})</h4>
                                    </div>
                                    <CardBody>
                                        <table className="display nowrap table table-hover table-striped table-bordered">
                                            <thead>
                                                <th>CUIT</th>
                                                <th>Razon Social</th>
                                                <th>Rol</th>
                                                <th>Permisos asignados</th>
                                            </thead>
                                            <tbody>
                                                {companies &&
                                                    companies.map(v => {
                                                        return (
                                                            <tr key={Date.now()}>
                                                                <td>{v.cuit}</td>
                                                                <td>{v.name}</td>
                                                                <td><div className="label label-table label-info">{v.role}</div></td>
                                                                <td>{v.modulesPermited.length}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card> */}
                            </>
                        }
                    </div>
                </div>
            </Main>
        )
    }
}
