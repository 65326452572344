import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import helper from '../helper';
import requests from '../../requests';


export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: null,
            turns: [],
            futils: {}
        }
    }

    componentDidMount() {
        document.title = helper.get_title('Home');
        // this.get_futils();
    }

    get_futils = async () => {
        const req = new requests({ name: 'futils' });
        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                this.setState({ futils: q.Data});
            } 
            // setloading(false);
        } catch (e) {
            this.setState({redirect: '/'});
            console.log(e);
        }
    }


    render() {
        const { loading, redirect } = this.state;
        return (
            <Main loading={loading} redirect={redirect} footer='position-fixed'>
                <>
                <SectionTitle 
                    title="Dashboard"
                    breadcrumb={[{name: "Home"}]}
                />
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <CardBody>

                            </CardBody>
                        </Card>
                    </div>
                </div>
                </>
            </Main>
        )
    }
}