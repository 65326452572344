import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Spinner from '../UI/Spinner';
import { Redirect } from 'react-router-dom';
import requests from '../../requests';
import swal from 'sweetalert';


export default class Main extends Component {
    constructor(props) {
        super(props); 
            this.state = {
                modulesPermited: [],
                redirect: false
            }
    }

    componentDidMount = () => {

    }

    checkPermission = () => {
        const { modulesPermited } = this.state;
        const { permission } = this.props;

        if(permission && modulesPermited.length > 0) {
            let check = modulesPermited.find(element => element.module === permission.module);

            if(!check) {
                swal("Error", "Estas intentando acceder a una sección no autorizada", 'error').then(() => {
                    this.setState({ redirect: '/' })
                })
            } else if(check.action !== 'all') {
                check = modulesPermited.find(element => element.module === permission.module && element.action === permission.action);
                if(!check){
                    swal("Error", "Estas intentando acceder a una sección no autorizada", 'error').then(() => {
                        this.setState({ redirect: '/' })
                    })
                }
            }
            
        }
    }
    
    render() {
        const { children, loading, redirect } = this.props;
        if(loading) {
            return <Spinner />
        }else if(redirect) {
            return <Redirect to={redirect} />
        }else if(!requests.getUserData()) {
            requests.clearData();
            return <Redirect to='/'/>
        }

        this.checkPermission();


        return (
            <div className="main-wrapper">
                <Sidebar />
                <div className="page-wrapper">
                    <div className="container-fluid overflow-hidden">
                    {children &&
                        children
                    }
                    </div>    
                </div>
                {/* <Footer classAdd={footer} />                 */}
            </div>
        )
    }
}
