import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import swal from 'sweetalert';
export default class PlanLevels extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: null,
            levels: [],
            authorizedSend: false
        }
    }

    handleChange = (e) => {
        let { id, value } = e.target;
        let { levels } = this.state;

        // Dividimos el ID
        const [ LevelID, FieldName ] = id.split('|');
    
        if(LevelID === undefined || !FieldName) return;

        // buscamos el ID del plan actual
        const levelIndex = levels.findIndex(e => e.id == LevelID);

        if(levelIndex === -1) return;

        switch(FieldName) {
            case 'vMin': 
                // Seteaos el valor minimo y el maximo del anterior
                if(value === "") { value = 0 }
                levels[levelIndex].vMin = parseFloat(value);
                if(levelIndex > 0) {
                    levels[levelIndex - 1].vMax = parseFloat(value);
                }
            break;
            case 'vMax': 
                // Seteaos el valor maximo y el minimo del siguiente
                if(value === "") { value = 0 }
                levels[levelIndex].vMax = parseFloat(value);
                if(levels[levelIndex + 1]) {
                    levels[levelIndex + 1].vMin = parseFloat(value);
                }
            break;
            case 'name':
                // Comprobamos que no exista otro con ese nombre
                if(!levels.find(e => e.name === value)) {
                    levels[levelIndex].name = value;
                }
            break;
            default:
                return false;
        }

        this.setState({ levels });
        this.check_values(levels);
    }

    check_values = (levels) => {
        // Obtenemos los valores y escaneamos para habilitar el boton
        let { authorizedSend } = this.state;

        let temp_levels = levels.sort((a,b) => a.vMin - b.vMin);
        authorizedSend = true;

        let last_vMax = 0;
        
        for(const { vMin, vMax } of temp_levels) {
            if(vMin !== last_vMax || vMax <= vMin) {
                authorizedSend = false;
            } 

            last_vMax = vMax;
        }

        this.setState({ authorizedSend })

    }

    componentDidMount = async () => {
        this.setState({ loading: true });
        let req = new requests({
            name: "plans",
            extraUrl: `/levels`
        })
        try {
            let q = await req.prepareConfig().get();
            this.setState({ loading: false })

            if(q.Data) {
                q.Data = q.Data.map((v, i) => {
                    return {
                        ...v,
                        id: Date.now() * i
                    }
                })
                this.setState({ levels: q.Data });
            }
        } catch (e) {
            console.error(e)
        }
    }

    modify = async (e) => {
        // this.setState({ loading: true })
        e.preventDefault();

        let req = new requests({
            name: 'plans',
            extraUrl: `/levels`,
            body: this.state.levels
        })

        try {
            let q = await req.prepareConfig().put();
            this.setState({ loading: false })
            if(q.Success) {
                swal('Excelente', 'Matriz modificada exitosamente', 'success').then(() => {
                    window.location.reload(window.location.href);
                })
            } else {
                swal('Error', q.Message, 'error')
            }
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    deleteRegister = (id) => {
        let { levels } = this.state;

        levels = levels.filter(e => e.id !== id);

        this.setState({ levels });
    }

    addRegister = () => {
        let { levels } = this.state;

        // Obtenemos el ultimo
        let last = levels[levels.length - 1];

        if(!last){
            last = { vMax: 0 }
        }

        levels.push({
            id: Date.now() * levels.length + 1,
            name: "New",
            vMin: last.vMax,
            vMax: last.vMax > 0 ? last.vMax * 2 : 1
        })

        this.setState({ levels });
    }

    render() {
        const { loading, redirect, levels } = this.state;
        return (
            <Main loading={loading} redirect={redirect} footer='position-fixed'>
                <SectionTitle 
                    title="Niveles"
                    breadcrumb={[
                        {name: 'Home', redirect: '/'},
                        {name: 'Niveles'}
                    ]}
                />
                <div className="row">
                    <div className="col-12">
                        {levels &&
                            <>
                                <Card>
                                    <div className="card-header bg-info">
                                    <h4 className="m-b-0 text-white">Niveles</h4>
                                    </div>
                                    <CardBody>
                                        <table className="display nowrap table table-hover table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <td>Nivel</td>
                                                    <td>Valor minimo</td>
                                                    <td>Valor máximo</td>
                                                    <td>Eliminar</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {levels.map((v, i) => {
                                                    return (
                                                        <tr>
                                                            <td className="input_table">
                                                                <input value={v.name} id={`${v.id}|name`}  onChange={this.handleChange}/>
                                                            </td>
                                                            <td className="input_table">
                                                                <input value={v.vMin} id={`${v.id}|vMin`} onChange={this.handleChange}/>
                                                            </td>
                                                            <td className="input_table">
                                                                <input value={v.vMax} id={`${v.id}|vMax`} onChange={this.handleChange}/>
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-outline-dark btn-xs" onClick={() => this.deleteRegister(v.id)}><i className="ti-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        <button type="button" onClick={this.addRegister} className="col-12 col-md-1 btn btn-info"><i className="fa fa-plus"></i> Nuevo</button>
                                        <button type="button" disabled={!this.state.authorizedSend} onClick={this.modify} className="col-12 ml-md-2 col-md-3 btn btn-success"><i className="fa fa-save"></i> Guardar</button>

                                    </CardBody>

                                </Card>
                            </>
                        }
                    </div>
                </div>
            </Main>
        )
    }
}
