import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

/**
 *  <SectionTitle 
        title="Mi Perfil"
        breadcrumb={[{name: "Homee", redirect: "/"},{name: 'Profile'}]} 
        redirect={(redirect) => this.setState(redirect)}
        button={[{name: "Puto", icon: "fa fa-plus-circle", onClick: (c) => console.log(c) }]}/>
 */

export default class SectionTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null
        }
    }
    redirect = (e) => {
        e.preventDefault();
        const redirect = e.target.dataset.redirect;
        this.setState({redirect})
    }

    render() {
        const { title, button, breadcrumb } = this.props;
        const { redirect } = this.state;
        if(redirect) {
            return <Redirect to={redirect}/>
        }

        return (
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                <h4 className="text-themecolor">{title}</h4>
                </div>    
                <div className="col-md-7 align-self-center text-right">
                    <div className="d-flex justify-content-end align-items-center">
                        {breadcrumb &&
                            <ol className="breadcrumb">
                                {breadcrumb.map((v, i) => {
                                    let data;
                                    if(v.redirect) {
                                        data = <li key={i} className="breadcrumb-item"><a data-redirect={v.redirect} href="/#" onClick={this.redirect}>{v.name}</a></li>;
                                    } else {
                                        data = <li key={i} className="breadcrumb-item active">{v.name}</li>
                                    }

                                    return data
                                })}
                            
                            </ol>
                        }
                        {button &&
                            button.map((v, i) => {
                                return (
                                    <button 
                                        key={i}
                                        type="button" 
                                        disabled={v.disabled}
                                        onClick={e => {
                                            e.preventDefault();
                                            v.onClick();
                                        }} 
                                        className="btn btn-info d-none d-lg-block m-l-15">
                                            <i className={v.icon || ''}></i> {v.name}
                                    
                                    </button>)
                            })

                        }
                    </div>
                </div>
            </div>
        )
    }
}
